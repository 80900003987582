

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

import Resizer from "react-image-file-resizer";

import { IconButton, Switch, Button, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';
import axios from './Services/apicall';


export default function Posts() {
  const [posts, setPosts] = React.useState([]);      
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [content, setContent] = React.useState('');
  const editor = React.useRef(null);

  const [ImgUrl, setImgUrl]=React.useState('');


  const [open, setOpen] = React.useState(false);
  const [post, postType] = React.useState('');
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    postType('');
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    postType(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(file, 500, 500, "jpeg", 100, 0, (uri) => {resolve(uri)}, "base64")
});

const onChange = async (event) => {
  try {
    const file = event.target.files[0];
    const image = await resizeFile(file);
    setImgUrl(image);
  } catch (err) {
    console.log(err);
  }
};

  const submitHandler=(event)=>{
    setOpen(true);
    event.preventDefault();
    const data = new FormData(); 
    data.append('title', event.target.title.value);
    data.append('description', content);
    data.append('category', post);
    data.append('image', ImgUrl);
    axios.post('/posts/create', data).then(res => { setPosts(res.data.data.all_posts); setOpen(false); });
    //   setOpen(false)
    //   if(res.data.u_detail.length===0){
    //       invalid_user.current = ('The provided credentials are incorrect.');
    //   }
    //   else{
    //         if(event.target.email.value==='admin@gmail.com'){

    //                 updateUser(res.data.u_detail[0]);
    //                 invalid_user.current='';
    //                 localStorage.setItem('user_data', JSON.stringify(res.data.u_detail[0]));
    //                 event.target.reset();
    //                 navigate('/dashboard');
    //         }
    //         else{
    //                 invalid_user.current='you are not admin';
    //                 setOpen(false);
    //         }
    //   }
    //   setError([]);
    // }).catch(err => {setError(err.response.data); setOpen(false); invalid_user.current='';});
   
}

const delete_post=(id)=>{
  const data = new FormData(); 
  data.append('id', id);
  axios.post('/posts/delete', data).then(res => { setPosts(res.data.data.all_posts) });
}
  React.useEffect(() => {
        // const current_user = JSON.parse(localStorage.getItem('user_data'))
        axios.get('/posts/all').then(res => {setPosts(res.data.data.all_posts)}); 
        
    },[])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={4}>
                <h1>Posts</h1>
              </TableCell>
              <TableCell colSpan={2}>
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>New Post</Button>
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Post Title</TableCell>
                <TableCell>Publish Date</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
               {posts.map((post, index)=>
          <TableRow hover key={index}>
                <TableCell>{post.category}</TableCell>
                <TableCell>{post.title}</TableCell>
                <TableCell>{post.post_date}</TableCell>
                <TableCell><img src={post.img_path} alt='pic' style={{width:'70px', height:'80px'}}/></TableCell>
                <TableCell>{post.status===1?<Switch defaultChecked />:<Switch />}</TableCell>
                <TableCell>
                    <IconButton color="warning" aria-label="upload picture" component="label" onClick={() => {delete_post(post.id)}}>
                      <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
          </TableRow>
          )} 
          
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={20}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<Dialog open={open} onClose={handleClose}>
  <form onSubmit={submitHandler}>
        <DialogTitle>Post Form</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
                          <Box sx={{ minWidth: 500 }}>
                            <br/>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select 
                          labelId="demo-simple-select-label"
                          value={post}
                          label="Category"
                          onChange={handleChange}
                        >
                          <MenuItem value={'counseling'}>Student Counseling</MenuItem>
                          <MenuItem value={'internships'}>Internships</MenuItem>
                          <MenuItem value={'scholarships'}>Scholarships</MenuItem>
                        </Select>
                        <br/>
                        <TextField  id="outlined-basic" name="title" label="Title" variant="outlined" />
                        <br/>
                        <JoditEditor ref={editor} value={content} onChange={newContent=>setContent(newContent)}/>
                       
                        <br/>
                        <input name='img' accept="image/*" type="file" onChange={(e)=> {onChange(e)}}/>
                      </FormControl>
                    </Box>
                    
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <button>Publish Post</button>
        </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}