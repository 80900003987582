

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NoteAddIcon from '@mui/icons-material/NoteAdd';


import { IconButton, Switch, Button, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';
import axios from '../Services/apicall';


export default function Banners() {
  const [banners, setBanners] = React.useState([]);      
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [ImgUrl, setImgUrl]=React.useState('');


  const [open, setOpen] = React.useState(false);
  const [cat, setCat] = React.useState('');
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCat('');
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setCat(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitHandler=(event)=>{
    setOpen(true);
    event.preventDefault();
    const data = new FormData(); 
    data.append('banner_title', event.target.banner_title.value);
    data.append('category', cat);
    data.append('navigation', event.target.navigation.value);
    data.append('image', ImgUrl);
    axios.post('https://api.letshareit.app/banners/banner_create', data).then(res => { setBanners(res.data.data.all_banners); setOpen(false);  setCat('');}); 
}


const delete_item=(id)=>{
  const data = new FormData(); 
  data.append('id', id);
  axios.post('/shop/delete', data).then(res => { setBanners(res.data.data.all_banners) });
}
  React.useEffect(() => {
        // const current_user = JSON.parse(localStorage.getItem('user_data'))
        axios.get('https://api.letshareit.app/banners/all_banners').then(res => {setBanners(res.data.data.all_banners)}); 
        
    },[])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={4}>
                <h1>Advertisement Banners</h1>
              </TableCell>
              <TableCell colSpan={2}>
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>New Banner</Button>
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Sr </TableCell>
                <TableCell>Banner Name</TableCell>
                <TableCell>Banner Category</TableCell>
                <TableCell>Banner Image</TableCell>
                <TableCell>Navigation Path</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
               {banners.map((banner, index)=>
          <TableRow hover key={index}>
                <TableCell>{index+1}</TableCell>
                <TableCell>{banner.banner_title}</TableCell>
                <TableCell>{banner.banner_cat}</TableCell>
                <TableCell><img src={banner.banner_url} alt='pic' style={{width:'170px', height:'80px'}}/></TableCell>
                <TableCell>{banner.navigation_path}</TableCell>
                <TableCell>{banner.is_active===1?<Switch defaultChecked />:<Switch />}</TableCell>
                <TableCell>
                    <IconButton color="warning" aria-label="upload picture" component="label" onClick={() => {delete_item(banner.id)}}>
                      <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
          </TableRow>
          )} 
          
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={20}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<Dialog open={open} onClose={handleClose}>
  <form onSubmit={submitHandler}>
        <DialogTitle>Add Item Form</DialogTitle>
        <DialogContent>

                      <Box sx={{ minWidth: 500 }}>
                           
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select 
                          labelId="demo-simple-select-label"
                          value={cat}
                          label="Category"
                          onChange={handleChange}
                        >
                          <MenuItem value={'Home'}>Home Banner</MenuItem>
                          <MenuItem value={'Shop'}>Shop Banner</MenuItem>
                        </Select>
                        <br/>
                       
                        <TextField  id="outlined-basic" name="banner_title" label="Banner Name" variant="outlined" />
                        <br/>
                        <TextField  id="outlined-basic" name="navigation" label="Navigation Path" variant="outlined" /> <h5>/path/path</h5>
                        <br/>
                        <input name='img' accept="image/*" type="file"  onChange={(event) => { setImgUrl(event.target.files[0]); }}/><h5>341 x 132 size .png</h5>
                      </FormControl>
                    </Box>
                    
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color='warning'>Cancel</Button>
          <button className="save-btn">SAVE</button>
        </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}