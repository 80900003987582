import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { useState, createContext } from 'react'
import Header from './Components/Header'
import Login from './Login'
import Dashboard from './Dashboard'
import Users from './Users'
import Notifications from './Notifications'
import Error from './Error'
import Terms from './Terms'
import Privacy from './Privacy'
import ProtectedRoute from './Services/ProtectedRoute'
import Posts from './Posts'
import Items from './Shop/Items'
import Orders from './Shop/Orders'
import Banners from './Shop/Banners'
import Hostels from './Hostels/hostels'

export const GlobalInfo = createContext()

export default function App() {
    const [user, setUser] = useState('Context Api')
    const [isAuth, setisAuth] = useState(false)

    const updateUser=(data)=>{
      setUser(data)
      setisAuth(true)
    }
  return (
    <GlobalInfo.Provider value={{user, updateUser}}>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<ProtectedRoute isAuth={isAuth}><Header /></ProtectedRoute>}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/items" element={<Items />} />
        <Route path="/hostels" element={<Hostels />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/banners" element={<Banners />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/terms-conditions" element={<Terms />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="*" element={<Error />} />
    </Routes>
    </BrowserRouter>
    </GlobalInfo.Provider>
  );
}