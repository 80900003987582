
import { Grid, Box, TextField, CircularProgress, Backdrop } from '@mui/material'
import React, { useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from './Services/apicall'
import { GlobalInfo } from './App'

export default function Login(){
        const {updateUser} = useContext(GlobalInfo) 
        let navigate = useNavigate();
        const invalid_user = useRef('');
        const [error, setError] = useState([]);
        const [open, setOpen] =   useState(false);

        const submitHandler=(event)=>{
                setOpen(true)
                event.preventDefault();
                const data = new FormData(); 
                data.append('email', event.target.email.value);
                data.append('password', event.target.password.value);
                axios.post('/user/login',data).then(res => { 
                  setOpen(false)
                  if(res.data.u_detail.length===0){
                      invalid_user.current = ('The provided credentials are incorrect.');
                  }
                  else{
                        if(event.target.email.value==='admin@gmail.com'){

                                updateUser(res.data.u_detail[0]);
                                invalid_user.current='';
                                localStorage.setItem('user_data', JSON.stringify(res.data.u_detail[0]));
                                event.target.reset();
                                navigate('/dashboard');
                        }
                        else{
                                invalid_user.current='you are not admin';
                                setOpen(false);
                        }
                  }
                  setError([]);
                }).catch(err => {setError(err.response.data); setOpen(false); invalid_user.current='';});
               
        }
    

return(
        <div className='main-div'>
        <form onSubmit={submitHandler}>
          <div className='container-change-white'>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} p={3}>
            <Grid item xs={12} className='center'>
            <img  src='/images/login-Logo.png' alt='logo' className='head-img' style={{marginTop:'25px'}}/>
                    {Object.values(error).map((er, index) => 
                      <h3 className='errorLogin' key={index}>{er[0]}</h3>
                    )}
                    <h3 className='errorLogin'>{invalid_user.current}</h3>
              </Grid>
              <Grid item xs={12} className='center'>
                  <TextField color='success' label='Email' name='email' type='email' variant='outlined' sx={{ width: '300px' }} />
              </Grid>
              <Grid item xs={12} className='center'>
                  <TextField  color='success' label='Password' name='password' type='password' variant='outlined' sx={{ width: '300px' }}/>
                  
              </Grid>
              <Grid item xs={12} className='center'>
                  <button className='custom-button' style={{marginBottom:'25px'}}>Login</button>
                  
                  <Backdrop open={open}>
                    <CircularProgress color="success" />
                  </Backdrop>
              </Grid>
            </Grid>
          </Box>
          </div>
        </form>
        </div>
)}