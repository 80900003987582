import { Navigate } from "react-router-dom";

function ProtectedRoute({isAuth, children}) {
    
        const u_data = JSON.parse(localStorage.getItem('user_data'))

        if(u_data){
            return children;
        }

        if(!isAuth){
            return <Navigate to="/login" />
        }

    return children;
}

export default ProtectedRoute

