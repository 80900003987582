import {AppBar,Toolbar,Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Notifications(){
    
let navigate = useNavigate();
    return(
           <div>
                <AppBar className='head-app-bar'>
                    <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                    <img  src='/images/shareLogo.png' alt='logo' style={{width:'100px'}}/>
                        <ul style={{listStyle: 'none', display: 'flex'}}>
                                <li><Button variant='secondary' onClick={()=>{navigate('/login')}}>Login</Button></li>
                        </ul>
                    </Toolbar>
                </AppBar>
                <div className='para-graph-container'>
                        <p className='term'>TERMS AND CONDITIONS</p><br/>
                        <p>Last updated: 2022-11-22</p><br/>
                        <p><b>1. Introduction</b></p><br/>
                        <p>Welcome to <b>let's Share it</b></p><br/>
                        <p>These Terms of Service govern your use of our website located at <b>https://letshareit.app</b> (together or individually “Service”) operated by <b>let's Share it</b> app.</p><br/>
                        <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.</p><br/>
                        <p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p><br/>
                        <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at info@letshareit.app so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p><br/>
                        <p><b>2. Communications</b></p><br/>
                        <p>By using our app, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at info@letshareit.app.</p><br/>
                        <p><b>3. Contests, Sweepstakes and Promotions</b></p><br/>
                        <p>Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through app may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.</p><br/>
                        <p><b>4. Content</b></p><br/>
                        <p>Content found on or through this APP are the property of let's Share it or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p><br/>
                        <p><b>5. Prohibited Uses</b></p><br/>
                        <p>You may use APP only for lawful purposes and in accordance with Terms. You agree not to use APP:</p><br/>
                        <p>0.1. In any way that violates any applicable national or international law or regulation.</p>
                        <p>0.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
                        <p>0.3. To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
                        <p>0.4. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</p>
                        <p>0.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
                        <p>0.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of APP, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</p><br/>
                        <p>Additionally, you agree not to:</p><br/>
                        <p>0.1. Use App in any manner that could disable, overburden, damage, or impair app or interfere with any other party’s use of Service, including their ability to engage in real time activities through APP.</p>
                        <p>0.2. Use any robot, spider, or other automatic device, process, or means to access APP for any purpose, including monitoring or copying any of the material on APP.</p>
                        <p>0.3. Use any manual process to monitor or copy any of the material on APP or for any other unauthorized purpose without our prior written consent.</p>
                        <p>0.4. Use any device, software, or routine that interferes with the proper working of Service.</p>
                        <p>0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
                        <p>0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of APP, the server on which Service is stored, or any server, computer, or database connected to APP.</p>
                        <p>0.7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
                        <p>0.8. Take any action that may damage or falsify Company rating.</p>
                        <p>0.9. Otherwise attempt to interfere with the proper working of APP.</p><br/>
                        <p><b>6. Analytics</b></p><br/>
                        <p>We may use third-party Service Providers to monitor and analyze the use of our APP.</p><br/>
                        <p><b>7. No Use By Minors</b></p><br/>
                        <p>Service is intended only for access and use by individuals at least fourteen (14) years old. By accessing or using Service, you warrant and represent that you are at least fourteen (14) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least fourteen (14) years old, you are prohibited from both the access and usage of APP.</p><br/>
                        <p><b>8. Accounts</b></p><br/>
                        <p>When you create an account with us, you guarantee that you are above the age of 14, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on APP.</p>
                        <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
                        <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
                        <p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p><br/>
                        <p><b>9. Intellectual Property</b></p><br/>
                        <p>Let’s share it app and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of let&#39;s Share it and its licensors. APP is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of let's Share it.</p><br/>
                        <p><b>10. Copyright Policy</b></p><br/>
                        <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p><br/>
                        <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to info@letshareit.app, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims” You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</p><br/>
                        <p><b>11. DMCA Notice and Procedure for Copyright Infringement Claims</b></p><br/>
                        <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p><br/>
                        <p>0.1. an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;</p>
                        <p>0.2. a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</p>
                        <p>0.3. identification of the URL or other specific location on Service where the material that you claim is infringing is located;</p>
                        <p>0.4. your address, telephone number, and email address;</p>
                        <p>0.5. a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
                        <p>0.6. a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</p><br/>
                        <p>You can contact our Copyright Agent via email at info@letshareit.app.</p><br/>
                        <p><b>12. Error Reporting and Feedback</b></p><br/>
                        <p>You may provide us either directly at info@letshareit.app or via third party sites and tools with
                                information and feedback concerning errors, suggestions for improvements, ideas, problems,
                                complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree
                                that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or
                                interest in or to the Feedback; (ii) Company may have development ideas similar to the
                                Feedback; (iii) Feedback does not contain confidential information or proprietary information
                                from you or any third party; and (iv) Company is not under any obligation of confidentiality with
                                respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible
                                due to applicable mandatory laws, you grant Company and its affiliates an exclusive,
                                transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use
                                (including copy, modify, create derivative works, publish, distribute and commercialize)
                                Feedback in any manner and for any purpose.</p><br/>
                        <p><b>13. Links To Other Web Sites</b></p><br/>
                        <p>Our Service may contain links to third party web sites or services that are not owned or controlled by let's Share it.</p>
                        <p>let's Share it has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p><br/>
                        <p><b>14. Termination</b></p><br/>
                        <p>We may terminate or suspend your account and bar access to Service immediately, without prior
                                notice or liability, under our sole discretion, for any reason whatsoever and without limitation,
                                including but not limited to a breach of Terms.
                                If you wish to terminate your account, you may simply discontinue using Service.
                                All provisions of Terms which by their nature should survive termination shall survive
                                termination, including, without limitation, ownership provisions, warranty disclaimers,
                                indemnity and limitations of liability.</p><br/>
                        <p><b>15. Governing Law</b></p><br/>
                        <p>These Terms shall be governed and construed in accordance with the laws of Pakistan, which
                                governing law applies to agreement without regard to its conflict of law provisions.
                                Our failure to enforce any right or provision of these Terms will not be considered a waiver of
                                those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
                                remaining provisions of these Terms will remain in effect. These Terms constitute the entire
                                agreement between us regarding our Service and supersede and replace any prior agreements we
                                might have had between us regarding Service.</p><br/>
                        <p><b>16. Changes To Service</b></p><br/>
                        <p>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p><br/>
                        <p><b>17. Amendments To Terms</b></p><br/>
                        <p>We may amend Terms at any time by posting the amended terms on this site. It is your
                                responsibility to review these Terms periodically.
                                Your continued use of the Platform following the posting of revised Terms means that you
                                accept and agree to the changes. You are expected to check this page frequently so you are aware
                                of any changes, as they are binding on you.</p>
                        <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p><br/>
                        <p><b>18. Waiver And Severability</b></p><br/>
                        <p>No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
                        <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p><br/>
                        <p><b>19. Acknowledgement</b></p><br/>
                        <p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p><br/>
                        <p><b>20. Contact Us</b></p><br/>
                        <p>Please send your feedback, comments, and requests for technical support by email: info@letshareit.app.</p>
                </div>
        </div>
            
    )}