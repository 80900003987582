

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

import Resizer from "react-image-file-resizer";

import { IconButton, Switch, Button, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';
import axios from '../Services/apicall';


export default function Hostels() {
  const [hostels, setHostels] = React.useState([]);      
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [ImgUrl, setImgUrl]=React.useState('');


  const [open, setOpen] = React.useState(false);
  const [city, setCity] = React.useState('');
  const [content, setContent] = React.useState('');
  const editor = React.useRef(null);

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCity('');
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setCity(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(file, 500, 500, "jpeg", 100, 0, (uri) => {resolve(uri)}, "base64")
});

const onChange = async (event) => {
  try {
    const file = event.target.files[0];
    const image = await resizeFile(file);
    setImgUrl(image);
  } catch (err) {
    console.log(err);
  }
};

  const submitHandler=(event)=>{
    setOpen(true);
    event.preventDefault();
    console.log(content)
    const data = new FormData(); 
    data.append('title', event.target.title.value);
    data.append('description', content);
    data.append('city', city);
    data.append('price', event.target.price.value);
    data.append('image', ImgUrl);
    axios.post('https://api.letshareit.app/hostels/add_hostel', data).then(res => { setHostels(res.data.data.all_hostels); setOpen(false);  setCity('');}); 
}

const delete_item=(id)=>{
  const data = new FormData(); 
  data.append('id', id);
  axios.post('/hostels/hostel_del', data).then(res => { setHostels(res.data.data.all_hostels) });
}
  React.useEffect(() => {
        // const current_user = JSON.parse(localStorage.getItem('user_data'))
        axios.get('/hostels/all').then(res => {setHostels(res.data.data.all_hostels)}); 
        
    },[])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={4}>
                <h1>Hostels</h1>
              </TableCell>
              <TableCell colSpan={2}>
                <Button variant="contained" color="success" startIcon={<AddBoxIcon />} onClick={handleClickOpen}>Add New Hostel</Button>
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
               {hostels.map((hostel, index)=>
          <TableRow hover key={index}>
                <TableCell>{hostel.title}</TableCell>
                <TableCell>{parse(hostel.detail)}</TableCell>
                <TableCell>PKR {hostel.price}/-</TableCell>
                <TableCell><img src={hostel.img} alt='pic' style={{width:'70px', height:'80px'}}/></TableCell>
                <TableCell>{hostel.is_active===1?<Switch defaultChecked />:<Switch />}</TableCell>
                <TableCell>
                    <IconButton color="warning" aria-label="upload picture" component="label" onClick={() => {delete_item(hostel.id)}}>
                      <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
          </TableRow>
          )} 
          
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={20}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<Dialog open={open} onClose={handleClose}>
  <form onSubmit={submitHandler}>
        <DialogTitle>Add Item Form</DialogTitle>
        <DialogContent>

                      <Box sx={{ minWidth: 500 }}>
                           
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                        <Select 
                          labelId="demo-simple-select-label"
                          value={city}
                          label="City"
                          onChange={handleChange}
                        >
                          <MenuItem value={'Multan'}>Multan</MenuItem>
                          <MenuItem value={'Lahore'}>Lahore</MenuItem>
                          <MenuItem value={'Karachi'}>Karachi</MenuItem>
                          <MenuItem value={'Islamabad'}>Islamabad</MenuItem>
                          <MenuItem value={'Faisalabad'}>Faisalabad</MenuItem>
                        </Select>
                        <br/>
                       
                        <TextField  id="outlined-basic" name="title" label="Hostel Name" variant="outlined" />
                        <br/>
                        <TextField  id="outlined-basic" name="price" label="Per Seat Price PKR" variant="outlined" />
                        <br/>
                        <JoditEditor ref={editor} value={content} onChange={newContent=>setContent(newContent)}/>
                        {/* <TextField  id="outlined-basic" name="desc" label="Description" variant="outlined" multiline rows="5"/> */}
                        <br/>
                        <input name='img' accept="image/*" type="file" onChange={(e)=> {onChange(e)}}/>
                      </FormControl>
                    </Box>
                    
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color='warning'>Cancel</Button>
          <button className="save-btn">SAVE</button>
        </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}