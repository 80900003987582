

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from '../Services/apicall';
import { Button, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';


export default function Orders() {
  const [order, setOrders] = React.useState([]);      
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [singleOrder, setSingleOrder] = React.useState('');
  const [Items, setItems] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [check, setCheck] = React.useState('');

  const handleClickOpen = (ord, check) => {
    setOpen(true);
    setCheck(check);
    setSingleOrder(ord);
    setItems(JSON.parse(ord.items_data))
  };


  const ordComplete = (checkBtn, id)=>{
    if(checkBtn==='cancel'){
      setOpen(false);
      var data = {status:'Cancel', order_id:id}
    }else if(checkBtn==='complete'){
      setOpen(false);  
      var data = {status:'Complete', order_id:id}
    }
    axios.post('/shop/update_order_status', data).then(res => { setOrders(res.data.data.all_orders); });
  };

  const submitHandler = () => {
    setOpen(true);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
   
        axios.get('/shop/all_orders').then(res => {setOrders(res.data.data.all_orders)}) 
        
    },[])

  return (
    <div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={7}>
                <h1>Orders</h1>
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell><b>Order No.</b></TableCell>
                <TableCell><b>Customer</b></TableCell>
                <TableCell><b>Contact #</b></TableCell>
                <TableCell><b>City</b></TableCell>
                <TableCell><b>Amount</b></TableCell>
                <TableCell><b>Mode</b></TableCell>
                <TableCell><b>Invoice</b></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
               {order.map((ord, index)=>
                    <TableRow hover key={index}>
                            <TableCell>{ord.order_no}</TableCell>
                            <TableCell>{ord.shipping_name}</TableCell>
                            <TableCell>{ord.shipping_phone}</TableCell>
                            <TableCell>Multan</TableCell>
                            <TableCell>{ord.total_amount}</TableCell>
                            <TableCell>{ord.payment_mode}</TableCell>
                            <TableCell> {ord.order_status === "Complete" ? <Button variant="contained" color='success' onClick={() => handleClickOpen(ord, 'check')}>Complete</Button> : ord.order_status === "In Process" ? <Button variant="contained" color= "secondary" onClick={() => handleClickOpen(ord)}>In Process</Button> : ord.order_status === "Cancel" ? <Button variant="contained" color='error' onClick={() => handleClickOpen(ord, 'check')}>Cancel</Button> : <h1>0</h1> }</TableCell>
                    </TableRow>
                 )} 
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={20}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<Dialog open={open}>

        <DialogTitle>Order No {singleOrder.order_no} - {singleOrder.order_status} </DialogTitle>
              <DialogContent>
         
               
                <h5>Order No: {singleOrder.order_no}</h5>
                <h5>Order Status: {singleOrder.order_status}</h5>
                <h5>Order Date: {singleOrder.order_time}</h5>  
                <h5>Payment Method: {singleOrder.payment_mode}</h5>  
                <h5>Customer Name: {singleOrder.shipping_name}</h5>  
                <h5>Customer Contact: {singleOrder.shipping_phone}</h5>  
                <h5>Customer Address: {singleOrder.shipping_address} ABC - Multan</h5>  
                
                <br/>

                <table style={{width:'100%'}}>
                  <tbody>
                      <tr style={{textAlign: 'left'}}>
                          <th>Image</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                          <th>Amount</th>
                      </tr>
                      {Items.map((it, index)=>
                          <tr key={index}>
                              <td><img src={it.item_img} width={'50'} height={'50'} alt='item_img' /></td>
                              <td>{it.item_name}</td>
                              <td>{it.qty}</td>
                              <td>{it.item_price}</td>
                          </tr>
                        )} 
                    </tbody>
                </table>
                <h3>Total Amount: {singleOrder.total_amount}</h3> 
                  
                </DialogContent>
                {check==='check'?
                    <DialogActions>
                        <Button variant="contained" color='warning' onClick={() => setOpen(false)} >Close</Button>
                    </DialogActions>
                :
                    <DialogActions>
                        <Button variant="contained" color='warning' onClick={() => setOpen(false)} >Close</Button>
                        <Button variant="contained" color='error' onClick={() => ordComplete('cancel', singleOrder.order_no)}>Cancel Order</Button>
                        <Button variant="contained" color='success' onClick={() => ordComplete('complete', singleOrder.order_no)} >Complete Order</Button>
                    </DialogActions>
                }
        

      </Dialog>
    </Paper>
    </div>
  );
}