

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Switch } from '@mui/material';
import axios from './Services/apicall';


export default function Users() {
  const [users, setUsers] = React.useState([]);      
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
        // const current_user = JSON.parse(localStorage.getItem('user_data'))
        axios.get('/user/users_all').then(res => {setUsers(res.data.user)}) 
        
    },[])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={6}>
                <h1>Registered Users</h1>
              </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>User Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
               {users.map((user, index)=>
          <TableRow hover key={index}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell><img src={user.img} alt='pic' style={{width:'70px', height:'80px'}}/></TableCell>
                <TableCell>
                {user.status===1?<Switch defaultChecked />:<Switch />}        
                
                </TableCell>
          </TableRow>
          )} 
          
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={20}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}